div {
  page-break-inside: avoid;
}

@media print {
  @page {
    size: auto;
    margin: 2mm;
    margin-bottom: 10mm;
  }

  body,
  html {
    zoom: 75%;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  div {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  h2 {
    page-break-inside: avoid;
  }

  footer {
    visibility: hidden !important;
  }

  .print-hidden {
    display: none !important;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}
