/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FFFFFF;
  box-shadow: 9999px 0 0 -5px #FFFFFF;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FFFFFF;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #FFFFFF;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #FFFFFF;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #FFFFFF;
  }

  30% {
    box-shadow: 9984px 0 0 2px #C09A5D;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #FFFFFF;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #FFFFFF;
  }

  30% {
    box-shadow: 9999px 0 0 2px #C09A5D;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #FFFFFF;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #FFFFFF;
  }

  30% {
    box-shadow: 10014px 0 0 2px #C09A5D;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #FFFFFF;
  }
}

/* Style the overlay */
.overlay {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s;
}

.overlayInner {
  height: 0;
  position: fixed;
  top: 200px;
}
